import React, { useState, useEffect} from 'react';
import apiSentryDynamicsArcGIS from '../../services/api-sentry-dynamics-arcGIS';
import { fromMapOverlaysToJSON, SentryTileMapType } from '../../services/google-map-support';

const ResultsMap = ({ id, parcelsToShowOnMap, mapStateAbbrev, mapCounty, setExpandedProperty, expandedProperty, onSelectedMapProperty, reloadSelectedProperty, exploreMore }) => {
    const [map, setMap] = useState(null);    
	const [reloadingMap, setReloadingMap] = useState(false);    
	const [geoJsonFeatures, setGeoJsonFeatures] = useState([]);
	const [listener, setListener] = useState(null);
	const [infoWindowParcelId, setInfoWindowParcelId] = useState(null);
	const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

	var selectedStyle = {
		fillColor: "#0df629",
		fillOpacity: 0.5,
		strokeColor: "#ffffff",
		strokeWeight: 3,
		strokeOpacity: 1,
		zIndex: 100
	};

	var shownStyle = {
		fillColor: "#cdcdcd",
		fillOpacity: .3,
		strokeColor: "#ed6100",
		strokeWeight: 2,
		strokeOpacity: 1,
		zIndex: 1
	}
	useEffect(() => {
		if (map && expandedProperty && id === "searchResultsMap") {		
			map.data.setStyle(function(feature) {
				map.data.revertStyle();
				return feature.getProperty('PARCELID') === expandedProperty.parcelId ? selectedStyle : shownStyle;
			}); 
			map.setCenter(new window.google.maps.LatLng(expandedProperty.latitude, expandedProperty.longitude));
			map.setZoom(18);
			setMap(map);
		}
	}, [expandedProperty, id])

	useEffect(() => {
		if (!map || !mapStateAbbrev || !mapCounty) return;
		map.overlayMapTypes.insertAt(
			0,
			new SentryTileMapType(window.google.maps, new window.google.maps.Size(256, 256), mapStateAbbrev, mapCounty)
		);

	}, [map, mapStateAbbrev, mapCounty]);

	useEffect(() => {
		if (reloadSelectedProperty) setReloadingMap(true);
	}, [reloadSelectedProperty]);

	useEffect(() => {
		if (map && parcelsToShowOnMap && reloadingMap) {
			highlightParcelsOnMap(map, parcelsToShowOnMap);
			setReloadingMap(false);
		}
		setMap(map);
	}, [parcelsToShowOnMap, map, reloadingMap])

	const addParcelOutlineGeoJson = async (map, selectedParcels) => {
		apiSentryDynamicsArcGIS.get(mapStateAbbrev +
			"/geo_" + mapStateAbbrev.toLowerCase() + "_" + mapCounty.toLowerCase() + "/MapServer/1/query?where=PARCELID+IN+" + encodeURIComponent("(\"" + selectedParcels + "\")").replace("(", "%28").replace(")", "%29")
			+ "&f=geojson")
			.then((response) => {
				response.data.features.forEach(e => {
					if (e.geometry.type === 'Polygon') {
						e.geometry.coordinates[0].forEach(c => {
							if (c.length > 2) c.pop();
						})
					}
				});
				var features = map.data.addGeoJson(response.data);
				features.forEach(f => { geoJsonFeatures.push(f); });
				setGeoJsonFeatures(geoJsonFeatures);
			});
	};

	const highlightParcelsOnMap = (map, parcelList) => {
		const bounds = new window.google.maps.LatLngBounds();
		var selectedParcels = "";
		if (parcelList.length === 0) return;
		if (parcelList.length === 1) {
			map.setCenter(new window.google.maps.LatLng(parcelList[0].latitude, parcelList[0].longitude));
			map.setZoom(18);
			selectedParcels += "'" + parcelList[0].parcelId + "',";
		}
		else 
		{
			parcelList.forEach((result) => {
				if (result.latitude && result.longitude) {
					bounds.extend(new window.google.maps.LatLng(result.latitude, result.longitude));
				}
				selectedParcels += "'" + result.parcelId + "',";
			});
			map.fitBounds(bounds);
		}
		selectedParcels = selectedParcels.length > 5 ? selectedParcels.slice(0, -1) : selectedParcels;
		map.data.setStyle(function (feature) {
			if (feature.getProperty('PARCELID') === expandedProperty.parcelId) {
				return selectedStyle;
			}
			else {
				return shownStyle;
			}
		});

		if (setExpandedProperty) {
			if (listener) listener.remove();
			var dataListener = map.data.addListener('click', function (event) {
				setExpandedProperty(parcelList.find(r => r.parcelId === event.feature.getProperty('PARCELID')));
			});
			setListener(dataListener);
		}
		geoJsonFeatures.forEach(f => {
			map.data.remove(f);
		});
		addParcelOutlineGeoJson(map, selectedParcels);

	};

	useEffect(() => {
		if (!window.google.maps) {
			(g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
				key: googleMapsAPIKey,
				v: "weekly",
			});
		}
	 }, [exploreMore]);

	useEffect(() => {
		if (parcelsToShowOnMap && parcelsToShowOnMap.length > 0) createGoogleMap();
	 }, [parcelsToShowOnMap]);


	async function createGoogleMap() {
		const { Map, InfoWindow } = await window.google.maps.importLibrary("maps");
        var center = { lat: 43.667872, lng: -116.325073 };

        var map = new Map(exploreMore ? document.getElementById("exploreMoreMap") : document.getElementById("resultsMap"), {
		    zoom: 8,
		    center: center,
		    zoomControlOptions: {
			    position: window.google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
		    },
            mapId: exploreMore ? '57f7780f5225eee7' : '31f93944666a580c'
	    });
		setMap(map);
		highlightParcelsOnMap(map, parcelsToShowOnMap);
		var infoWindow = new InfoWindow();
		window.google.maps.event.addListener(map, "click", function (evt) {
			var geometry = fromMapOverlaysToJSON(evt.latLng, window.google.maps);
			var mapExtent = fromMapOverlaysToJSON(map.getBounds(), window.google.maps);
			var imageDisplay = "" + map.getDiv().offsetWidth + "," + map.getDiv().offsetHeight + "," + 96;
			var url = "?geometry=" + geometry + "&geometryType=esriGeometryPoint&mapExtent=" + mapExtent +
				"&tolerance=3&sr=4326&imageDisplay=" + imageDisplay + "&layers=all:1&returnGeometry=true&f=json";
			const IdentifyParcel = async () => {
				apiSentryDynamicsArcGIS.get(mapStateAbbrev +
					"/geo_" + mapStateAbbrev.toLowerCase() + "_" + mapCounty.toLowerCase() + "/MapServer/identify/"
					+ url)
					.then((response) => {
						if (response.data.results) {
							setInfoWindowParcelId(response.data.results[0].attributes.PARCELID);
							var popUpContent = `<div class="d-flex flex-column">
								<div tabIndex="0" class="fw-bold text-center no-localization">${response.data.results[0].attributes.SITEADDR || response.data.results[0].attributes.SITUS || response.data.results[0].attributes.PARCELID}</div>
								<div class="fw-bold text-center no-localization">${response.data.results[0].attributes.OWNERNM}</div>
								<div class="fw-bold text-center no-localization">Parcel#: ${response.data.results[0].attributes.PARCELID}</div>
								<input type="button" class="btn btn-primary" value="Create Profile" onClick="document.getElementById('mapSelect').click();" />
							</div>`;
							infoWindow.setContent(popUpContent);
							infoWindow.setPosition(evt.latLng)								
							infoWindow.open({					
								map
							});  
						};
					});
			}
			IdentifyParcel();
		});		
    };

  return (
      <>
		  {parcelsToShowOnMap && !reloadingMap && !exploreMore && 
			<div className="col map-container">
				<div id="resultsMap" className="card"></div>			
			</div>}
		  {exploreMore && <div className="col map-container">
				<div id="exploreMoreMap" className="card"></div>			
			</div>}
		  {infoWindowParcelId && <input type="button" className={"d-none " + infoWindowParcelId} id="mapSelect" onClick={() => onSelectedMapProperty(infoWindowParcelId)} />	}
      </>
  );
}

export default ResultsMap;