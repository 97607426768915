import React, { Fragment } from 'react';
import { DateTime } from 'luxon';
import currency from 'currency.js';

const NetSheetComparisonSection = ({ netSheets, rowsToLeaveOut, dataSection, sectionName, netSheetsWithData }) => {

    const cleanUpRowName = (name) => {
        var rowName = name.charAt(0).toUpperCase() + name.slice(1).replace(/([a-z])([A-Z0-9])/g, '$1 $2');
        rowName = rowName.replace("Pct", "Percentage");
        rowName = rowName.replace("Buyers", "Buyer's");
        rowName = rowName.replace("Sellers", "Seller's");
        rowName = rowName.replace("Lenders", "Lender's");
        rowName = rowName.replace("Homeowners", "Homeowner's");
        rowName = rowName.replace("Owners", "Owner's");
        rowName = rowName.replace("Home Owner's", "Homeowner's");
        rowName = rowName.replace("Va ", "VA ");
        rowName = rowName.replace("Coverage One", "CoverageOne");
        rowName = rowName.replace("Buyer's EDoc", "eDoc");
        rowName = rowName.replace("Seller's EDoc", "eDoc");
        rowName = rowName.replace("Second Half", `*2nd Half ${netSheetsWithData[0].netSheetFormData.propertyCounty.stateAbbr === 'ID' ? 'Prior Year ' : ''}`);
        rowName = rowName.replace("Tax Proration", "*Tax Proration");
        return rowName;
    }

    const cleanUpFieldValue = (value, rowName) => {
        if (["discountPointsNumber", "reserveMonthsTaxes", "reserveMonthsInsurance", "discountPoints"].includes(rowName)) return value;
        if (rowName.includes('Pct') || rowName.includes('Rate') || rowName.includes("Percentage")) return value + '%';
        if (rowName.includes('Date')) return DateTime.fromISO(value).toLocaleString();
        if (value === 'EightyTwenty') return '80/20';
        return typeof value === "number" ? currency(value, { separator: ',', symbol: '$' }).format() : value;
    }

    const leaveRowOut = (row) => {
        if (rowsToLeaveOut.includes(row)) return true;
        if (dataSection === "netSheetFormData" && rowsToLeaveOut.includes("common_" + row)) return true;
        if (dataSection.includes("buyer") && rowsToLeaveOut.includes("buyer_" + row)) return true;
        if (dataSection.includes("seller") && rowsToLeaveOut.includes("seller_" + row)) return true;
        return false;
    }

    return (
        <><tr>
        <td className="fw-bold border-email-bottom" colSpan={netSheets.length + 1} >
            {sectionName}
                </td>
            </tr>
            {Object.keys(netSheetsWithData[0][dataSection]).map((row, i2) => {
                if (leaveRowOut(row)) return <Fragment key={"empty" + i2}></Fragment>
                return <Fragment key={dataSection + "row" + i2}>
                    <tr>
                        <td className="fw-bold text-end">{cleanUpRowName(row)}:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={dataSection + "field" + i} className="no-localization">{cleanUpFieldValue(ns[dataSection][row], row)}</td>
                        })}
                    </tr>
                </Fragment>
            })}
        </>
    );
}

export default NetSheetComparisonSection;