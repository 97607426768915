import React, { useState, useEffect } from 'react';
import ResultsMap from './results-map';

const ExploreMore = ({ state, county, resultsForMap, expandedProperty, onSelectedMapProperty, reloadSelectedProperty }) => {   
    const [mapResultForSingleProperty, setMapResultForSingleProperty] = useState(null);

    useEffect(() => {
        if (resultsForMap && expandedProperty) {
            const filteredResult = resultsForMap.filter(r => r.parcelId === expandedProperty.parcelId);
            setMapResultForSingleProperty(filteredResult);
        }
    }, [resultsForMap, expandedProperty]);


    return (
        <div>
            <ResultsMap id="exploreMore" parcelsToShowOnMap={mapResultForSingleProperty} mapStateAbbrev={state} mapCounty={county} expandedProperty={expandedProperty} onSelectedMapProperty={onSelectedMapProperty} reloadSelectedProperty={reloadSelectedProperty} exploreMore={true} />
        </div>    
    );     
}

export default ExploreMore;