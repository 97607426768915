import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { UserContext } from '../../contexts/user-context';
import apiTools from '../../services/api-tools';
import RegionSelector from '../preferred-customer/region-selector';
import './county-selector.scss';
import SpinnerLoader from './spinner-loader';

const CountySelector = ({regionId, toolName, toolDisplayName, toolRoute, style, selectedCounty, setSelectedCounty, paramCounty, allOption, loadedCounty, disabled}) => {
    const [counties, setCounties] = useState([]);

    const [loadingCounties, setLoadingCounties] = useState(true);
    const { loadingUser, pcUser } = useContext(UserContext);

    const setFirstCounty = (countyArray, toolName) => {
        if (loadedCounty) { 
            setSelectedCounty(countyArray.find(t => t.countyValue.toLowerCase().replace(' ', '') === loadedCounty.toLowerCase().replace(' ', '')))
            return;
        }
        if (paramCounty) { 
            setSelectedCounty(countyArray.find(t => t.countyValue.toLowerCase() === paramCounty.toLowerCase()))
            return;
        }

        if (document.cookie.includes(' ' + toolName + '-' + regionId)) {
            var cookie = document.cookie.split(';').find((item) => item.trim().startsWith(toolName + '-' + regionId + '=')).split('=')[1];
            if (countyArray.find(c => c.countyValue === cookie)) {
                setSelectedCounty(countyArray.find(c => c.countyValue === cookie));
                return;
            }
        }
        if (allOption) { 
            setSelectedCounty(countyArray);
            return;
        }
        setSelectedCounty(countyArray[0]);
    }

    useEffect(() => {
        const GetTaxLookup = async () => {
            setLoadingCounties(true);
            apiTools.get(`/tool-counties/Tax Lookup/${regionId}`)
                .then((response) => {
                    var taxLookupCounties = response.data;
                    const GetCounties = async (link) => { 
                        apiTools.get(link)
                            .then((countyResponse) => {
                                var countyArray = [];
                                countyResponse.data.forEach(function (county) {
                                    var countyName = county.countyValue;
                                    countyArray.push({...county, taxLookupEnabled: taxLookupCounties.findIndex(t => t.countyValue === countyName) > -1 })
                                });
                                setCounties(countyArray);
                                setLoadingCounties(false);
                                setFirstCounty(countyArray, toolName);
                            });
                        }
                    if (regionId && toolName) {
                        GetCounties(`/tool-counties/${toolName}/${regionId}`);
                    } else if (toolName) {
                        GetCounties(`/tool-counties/${toolName}/`);
                    } else {
                        GetCounties(`orderform-counties`);
                    }
                });
        }
        if ((!loadingUser && pcUser && regionId) || (regionId && disabled)) {
            GetTaxLookup();
        }   

    }, [loadingUser, pcUser, regionId, setSelectedCounty, toolName, paramCounty, loadedCounty]);

    useEffect(() => {
        if (selectedCounty && toolName && (selectedCounty.regionId === regionId && !Array.isArray(selectedCounty))) { 
            document.cookie = toolName + '-' + regionId + '=' + selectedCounty.countyValue + ';max-age=2592000; path=/';
        }
    }, [selectedCounty, toolName, regionId]);

    const onSelect = (e) => {
        if (e.target.value === 'all') {
            setSelectedCounty(counties);
        } else { 
            setSelectedCounty(counties.find(t => t.countyValue === e.target.value))
        }
    }

    return (<>
        {loadingCounties && <SpinnerLoader/>}
        {!loadingCounties && selectedCounty && <div>
            {counties.length > 1 &&
                <select disabled={disabled} name="County" className="form-select no-localization" aria-label="Select County" onChange={(e) => onSelect(e)} value={Array.isArray(selectedCounty) ? "all" : selectedCounty.countyValue}>
                    {allOption && <option key="all" value="all">- ENTIRE REGION -</option>}
                    {counties.map((c, idx) => <option key={idx} value={c.countyValue}>{c.county}</option>)}
                </select>
            }
            {counties.length === 1 &&
                <div className="fw-bold no-localization">{counties[0].county}</div>
            }
            <small><RegionSelector linkText="Find more counties" toolName={toolName} toolRoute={toolRoute} toolDisplayName={toolDisplayName} /></small>
        </div>}
        </>
    );
}

export default CountySelector;