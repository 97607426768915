import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiPublic from '../../services/api-public';
import apiTools from '../../services/api-tools';
import { FilePath, FormatPhoneNumber, FormatNameToFilePath } from "../../services/utilities";
import Select from 'react-select';
import { MdOutlineEmail } from 'react-icons/md'
import "./locations.scss"
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../contexts/brand-context';
import { useForm } from "react-hook-form";
import TeamMemberModalBody from './team-member-modal-body';
import { UserContext } from '../../contexts/user-context';

const Locations = () => {
    let { paramLocation } = useParams();
    const { selectedBrand } = useContext(BrandContext);
    const { activeRegion, pcUser } = useContext(UserContext);
    const [offices, setOffices] = useState([]);
    const [officesToDisplayForBrand, setOfficesToDisplayForBrand] = useState([]);
    const [officeNames, setOfficeNames] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedTeamMember, setSelectedTeamMember] = useState();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [emailSent, setEmailSent] = useState(false);
    const [csEmailAddress, setCsEmailAddress] = useState();

    useEffect(() => {
        if (!selectedBrand) return;
        const getOffices = async () => {

            const response = await apiPublic.get(`/offices/${selectedBrand.brandId}`);
            setOffices(response.data);
            var officeOptions = [];
            response.data.forEach((state) => {
                var names = [];
                state.forEach((office) => {
                    names.push({ value: office.officeName, label: office.officeName });
                });
                officeOptions.push({ label: state[0].stateFullName, options: names });
            });

            setOfficeNames(officeOptions);
        }
        getOffices();
    }, [selectedBrand]);

    useEffect(() => {
        if (activeRegion) {
            setCsEmailAddress(activeRegion.stateAndCounties[0].csEmailAddress);
        }
        else {
            setCsEmailAddress("cservice@titleonecorp.com");
        }

    }, [activeRegion]);

    useEffect(() => {
        const getTeamMembers = async () => {
            const response = await apiTools.get(`/team-by-location/${selectedLocation.officeName}`);
            setTeamMembers(response.data)
        }
        if (selectedLocation) getTeamMembers();
    }, [selectedLocation]);

    useEffect(() => {
        if (!offices || offices.length === 0) return;

        if (offices && paramLocation) {
            var location;
            offices.forEach(group => {
                var found = group.find(p => p.officeName === paramLocation);
                if (found) location = found;
            });
            if (location) {
                setSelectedLocation(location);
            }
            else {
                setSelectedLocation(null);
            }
        }
        else {
            setSelectedLocation(null);
            var brandOffices = offices.filter(x => x.length > 0);
            setOfficesToDisplayForBrand(brandOffices);
        }
        setLoading(false);
    }, [offices, paramLocation]);

    function handleSelectChange(selectedOption) {
        var location;
        offices.forEach(group => {
            var found = group.find(p => p.officeName === selectedOption.value);
            if (found) location = found;
        });
        if (location) {
            setSelectedLocation(location);
        }
    };

    const onSubmit = data => {
        const emailOfficeObject = {
            to: data.to,
            office: selectedLocation,
            brand: selectedBrand,
            notes: data.notes,
            image: FilePath("/locationimages/" + FormatNameToFilePath(selectedLocation.officeName) + ".jpg")
        }
        const sendEmail = async () => {
            await apiPublic.post(`/email-office-location`, emailOfficeObject).then((response) => setEmailSent(true));
        }
        sendEmail();
        setEmailSent(true);
    }

    const singleOfficeLayout = () => {
        var mapQuery = selectedLocation.address1 + ', ' + selectedLocation.city + ', ' + selectedLocation.stateAbbrev;
        return <>
            <div className="modal" id="emailDetailsModal" tabIndex="-1" aria-labelledby="emailDetailsModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="emailDetailsModalLabel">Email Office Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {emailSent ? <p>Thank you, your email has been sent.</p> : <div className="row">
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold required">To address(es):</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <input type="email" multiple className="form-control" id="emailOfficeInfoTo" {...register("to", { required: true })} />
                                        <small>Separate multiple addresses with a comma (,).</small>
                                        {errors?.to?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold">Notes:</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <textarea id="emailOfficeInfoNotes" rows="3" className="form-control" {...register("notes")} ></textarea>
                                    </div>
                                </div>}

                                <div className="modal-footer">
                                    {!emailSent && <button type="submit" className="btn btn-primary m-auto">Send Email</button>}
                                    <span onClick={() => setEmailSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{emailSent ? "Close" : "Cancel"}</button></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <p>With a team of the industry's top professionals, we offer you innovative technology, streamlined closings, and legendary service to ensure your title and escrow transactions are a success.</p>
            <div className="row">
                <div className="col-6 col-md-4 no-localization">
                    <Select aria-label="Select Office" isSearchable={false} value={{ value: selectedLocation.officeName, label: selectedLocation.officeName }} onChange={handleSelectChange} options={officeNames} />
                </div>
                <div className="col-6 col-md-8">
                    <button className="d-none d-sm-block btn btn-primary float-end m-1" data-bs-toggle="modal" data-bs-target="#emailDetailsModal"><MdOutlineEmail className="react-icons" /> Email Office Details</button>
                    <button className="d-sm-none btn btn-primary float-end btn-sm m-1" data-bs-toggle="modal" data-bs-target="#emailDetailsModal"><MdOutlineEmail className="react-icons" /> Email Office Details</button>
                    <a href={"mailto:" + csEmailAddress} className="btn btn-primary d-none d-sm-block float-end m-1" role="button"><MdOutlineEmail className="react-icons" /> Need Help?</a>
                    <a href={"mailto:" + csEmailAddress} className="d-sm-none btn btn-primary float-end btn-sm m-1" role="button"><MdOutlineEmail className="react-icons" /> Need Help?</a>
                </div>
            </div>
            <div className="row lh-lg mt-3">
                <div id="officeContactInfo" className="col-12 col-md-6 col-lg-4">
                    <h2><span className="no-localization">{selectedLocation.officeName}</span> Office</h2>
                    {selectedLocation.buildingName && <div className="no-localization">{selectedLocation.buildingName}</div>}
                    <div className="no-localization">{selectedLocation.address1}</div>
                    <div className="no-localization">{selectedLocation.city}, {selectedLocation.stateAbbrev} {selectedLocation.zip}</div>
                    <div><strong>Phone:</strong> {FormatPhoneNumber(selectedLocation.phone)}</div>
                    {selectedLocation.fax && <div><strong>Fax:</strong> {FormatPhoneNumber(selectedLocation.fax)}</div>}
                    {selectedLocation.licenseNum && <div><strong>License #{selectedLocation.licenseNum}</strong></div>}
                </div>
                <div className="col-12 col-md-6 col-lg-4 pb-2 overflow-hidden">
                    <img src={FilePath("/locationimages/" + FormatNameToFilePath(selectedLocation.officeName) + ".webp")} alt={selectedLocation.officeName + " Photo"} id="officePhoto" width="350" height="230" />
                </div>
                <div id="map2" className="col-12 col-lg-4 pb-2 overflow-hidden"><iframe title="office location map" width="350" height="230" id="mapEmbed" loading="lazy" src={"https://www.google.com/maps/embed/v1/place?q=" + encodeURIComponent(mapQuery) + "&zoom=14&key=" + process.env.REACT_APP_GOOGLE_MAPS_KEY}></iframe></div>
            </div>
            <div className="modal" id="teamMemberModal" tabIndex="-1" aria-labelledby="teamMemberModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="teamMemberModalLabel">Team Member Details</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <TeamMemberModalBody selectedTeamMember={selectedTeamMember} selectedOffice={{ value: selectedLocation.officeId, label: selectedLocation.officeName }} selectedBrand={selectedBrand} pcUser={pcUser} />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal" onClick={() => setSelectedTeamMember(null)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                {teamMembers && teamMembers.map(function (member, index) {
                    return <Fragment key={index}>
                        <div className="col-6 col-md-4 col-lg-3 text-center p-4 team-cards">
                            <span data-bs-toggle="modal" data-bs-target="#teamMemberModal" role="button" onClick={() => setSelectedTeamMember(member)}>
                                <img className="no-localization clickable" src={FilePath(`/teamimages/${FormatNameToFilePath(member.firstName + " " + member.lastName)}.png`)} alt={member.firstName + " " + member.lastName + " profile image"} width="120" height="120" />
                                <br />
                                <span className="no-localization team-member-name fw-bold text-primary">{member.firstName + " " + member.lastName}</span>
                                <br />
                                <span className="fw-bold py-3">{member.title}</span>
                            </span>
                        </div>
                    </Fragment>;
                })}
            </div>
        </>
    }

    const allOfficeLayout = () => {
        return <>
            <div className="row lh-lg">
                <div className="col-12 col-md-6">
                    <ul className="mb-0">
                        <li>View the <Link to="/counties" className="fw-bold">counties we serve</Link></li>
                        <li>Contact <a href={"mailto:" + csEmailAddress} className="fw-bold">Customer Service</a></li>
                        <li>Schedule a {(selectedBrand.brandId !== 2) ? <a href="mailto:moresales@titleonecorp.com" className="fw-bold">Web Demo</a> : <a href="mailto:gmaxwell@sunvalleytitle.com" className="fw-bold">Web Demo</a>} by email</li>
                    </ul>
                </div>
                <div className="col-12 col-md-6">
                    <ul className="mb-0">
                        {selectedBrand.brandId !== 2 && <li>Contact a <Link to="/client-relationship-form" className="fw-bold">Sales Executive</Link></li>}
                        <li>Suggest a <a href="mailto:t1webteam@titleonecorp.com" className="fw-bold">new feature</a> for the web site</li>
                        <li>Compliment or concern? Send us some <a href={"mailto:" + csEmailAddress} className="fw-bold">feedback!</a></li>
                    </ul>
                </div>
            </div>
            {officesToDisplayForBrand && officesToDisplayForBrand.map(function (state, stateIndex) {
                return <Fragment key={stateIndex}><div className="my-3"><h2 className="border-bottom">{state[0].stateFullName}</h2></div>
                    <div className="row">
                        {state && state.map(function (office, officeIndex) {
                            return <Link key={officeIndex} className="col-12 col-sm-6 col-md-4 col-lg-3 py-1 py-lg-3 px-3 px-lg-4 location-cards" tabIndex="0" to={"/locations/" + office.officeName}>
                                <img src={FilePath("/locationimages/" + FormatNameToFilePath(office.officeName) + ".webp")} alt={office.officeName + " Photo"} className="img-fluid" width="350" height="230" />
                                <div>
                                    <strong className="no-localization">{office.officeName}</strong>
                                    <div className="no-localization">
                                        {office.buildingName && <>{office.buildingName}<br /></>}
                                        {office.address1}<br />
                                        {office.city}, {office.stateAbbrev} {office.zip}
                                    </div>
                                    <strong>Phone:</strong> {FormatPhoneNumber(office.phone)}<br />
                                    <span className="fw-bold text-primary" role="button" aria-label={"Office Info and Map - " + office.officeName + " office"}>Office Info and Map</span>
                                </div>
                            </Link>;

                        })}</div></Fragment>;
            })}
        </>
    }

    return (
        <>
            <Helmet>
                <title>Contact and Locations {(selectedLocation) ? " - " + selectedLocation.officeName : ""} : {selectedBrand.brandName}</title>
                <meta name="description" content="We have several locations available to help serve your needs" />
            </Helmet>

            <h1>Contact & Locations</h1>
            {!loading &&
                <>
                    {selectedLocation ? singleOfficeLayout() : allOfficeLayout()}
                </>
            }
        </>
    );
}

export default Locations;