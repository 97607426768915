import React, { useState, useEffect, useContext } from 'react';
import { useForm} from "react-hook-form";
import { UserContext } from '../../../contexts/user-context';
import apiTools from '../../../services/api-tools';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import currency from 'currency.js';
import { EventTrack } from '../../../services/utilities';
import { clearZero, formatInput } from './utilities';
import CountySelector from '../../layout/county-selector';

const NetSheetCommonData = ({ className, submitData, saving, setCommonData, loadedNetSheet, paramCounty, paramParcelId, selectedCountyState, setSelectedCountyState }) => {
    const { activeRegion } = useContext(UserContext);
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

    /* watchers */
    const watchAddress = watch("propertyAddress");
    const watchAnnualTaxes = watch("annualTaxes");
    const watchAnnualTaxesPct = watch("annualTaxesPct");
    const watchPurchasePrice = watch("purchasePrice");
    const watchLoanType = watch("loanType", "Conventional");
    const watchBuyersDownPayment = watch("buyersDownPayment");
    const watchBuyersDownPaymentPct = watch("buyersDownPaymentPct");

    /* state vars */
    const [noResults, setNoResults] = useState(false);
    const [moreResults, setMoreResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [propertyInfo, setPropertyInfo] = useState(null);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [loadingPropertyResults, setLoadingPropertyResults] = useState(false);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const [changedElement, setChangedElement] = useState(null);
    const [countySettings, setCountySettings] = useState(null);

    useEffect(() => {
        if (!loadedNetSheet) return;
        var nonNumericFields = ['propertyaddress', 'city', 'buyername', 'sellername', 'preparedby'];
        for (const [key, value] of Object.entries(loadedNetSheet.netSheetFormData)) {
            if (key.toLowerCase() === 'closingdate') { 
                var date = DateTime.fromISO(value).toFormat('yyyy-MM-dd');
                setValue('closingDate', date);
            } else if (nonNumericFields.indexOf(key.toLowerCase()) > -1) { 
                setValue(key, value);
            } else if (value && value !== '' && value !== '0.00') {
                currency(value).value > 0 ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);              
            }
        }
        if (currency(loadedNetSheet.netSheetFormData.purchasePrice).value > 0) { 
            var taxesPct = (currency(loadedNetSheet.netSheetFormData.annualTaxes).value / currency(loadedNetSheet.netSheetFormData.purchasePrice).value) * 100;
            setValueCustom("annualTaxesPct", parseFloat(taxesPct.toFixed(3)));  
            var downPmtPct = (currency(loadedNetSheet.netSheetFormData.buyersDownPayment).value / currency(loadedNetSheet.netSheetFormData.purchasePrice).value) * 100;
            if (watchBuyersDownPaymentPct !== downPmtPct) setValueCustom("buyersDownPaymentPct", parseFloat(downPmtPct.toFixed(3)));
        }  

    }, [loadedNetSheet]);

    useEffect(() => {
        const GetCountyClosingCostSettings = async (state, county, transType) => {
            apiTools.get(`/closing-cost-calc/settings/${state}/${county}/${transType}`)
                .then((response) => {
                    setCountySettings(response.data);
                });
        };
        if (selectedCounty) {
            GetCountyClosingCostSettings(selectedCounty.state, selectedCounty.county, "Purchase");
        }
        
    }, [selectedCounty]);

    useEffect(() => {
        if (!selectedCounty) return;
        setSelectedCountyState(selectedCounty.state);
    }, [selectedCounty, setSelectedCountyState]);

    const setValueCustom = (el, val) => { 
        if (changedElement && changedElement.id === el) return;
        setValue(el, currency(val , { separator: ',', symbol: '' }).format());
    }

    const updateForm = (elementId) => { 
        if (watchPurchasePrice && currency(watchPurchasePrice).value !== currency(0).value) {
            var taxesPct = 0;
            switch (elementId) {
                case 'annualTaxesPct':
                    var taxes = currency(watchPurchasePrice).multiply(watchAnnualTaxesPct / 100).value;
                    if (watchAnnualTaxes !== taxes) setValueCustom("annualTaxes", taxes);
                    break;
                case 'annualTaxes':
                    taxesPct = (currency(watchAnnualTaxes).value / currency(watchPurchasePrice).value) * 100;
                    if (watchAnnualTaxesPct !== taxesPct) setValueCustom("annualTaxesPct", parseFloat(taxesPct.toFixed(3)));
                    break;
                case 'buyersDownPaymentPct':
                    var downPmt = currency(watchPurchasePrice).multiply(watchBuyersDownPaymentPct / 100).value;
                    if (watchBuyersDownPayment !== downPmt) setValueCustom("buyersDownPayment", downPmt);
                    break;
                case 'buyersDownPayment':
                    var downPmtPct = (currency(watchBuyersDownPayment).value / currency(watchPurchasePrice).value) * 100;
                    if (watchBuyersDownPaymentPct !== downPmtPct) setValueCustom("buyersDownPaymentPct", parseFloat(downPmtPct.toFixed(3)));
                    break;
                case 'purchasePrice':
                    taxesPct = (currency(watchAnnualTaxes).value / currency(watchPurchasePrice).value) * 100;
                    if (watchAnnualTaxesPct !== taxesPct) setValueCustom("annualTaxesPct", parseFloat(taxesPct.toFixed(3)));
                    updateBasedOnLoanType();
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {   
        if (!changedElement) return;
        if (watchBuyersDownPayment === "") setValue("buyersDownPayment", 0);
        if (watchBuyersDownPaymentPct === "") setValue("buyersDownPaymentPct", 0);
        if (changedElement.id !== 'loanType') updateForm(changedElement.id);
        if (watchLoanType === 'EightyTwenty') {
            setValue("buyersLoanAmount1", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.8).format());
            setValue("buyersLoanAmount2", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.2).format());
        } else {  
            setValueCustom("buyersLoanAmount1", currency(watchPurchasePrice).subtract(watchBuyersDownPayment));
        }
    }, [watchAnnualTaxes, watchPurchasePrice, watchAnnualTaxesPct,watchBuyersDownPayment, watchBuyersDownPaymentPct]);

    const updateBasedOnLoanType = () => { 
        if (!changedElement) return;
        switch (watchLoanType) {
          case 'Conventional':
                setValue("buyersDownPaymentPct", 20);
                setValue("buyersDownPayment", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.2).format());
            break;
          case 'FHA':
          case 'IHFA':  
                setValue("buyersDownPaymentPct", 3.5);
                setValue("buyersDownPayment", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.035).format());
            break;
          case 'VA':  
          case 'ZeroDown':
          case 'SellerCarryBack':  
                setValue("buyersDownPaymentPct", 0);
                setValue("buyersDownPayment", currency(0, { separator: ',', symbol: '' }).format());
                break;
          case 'Cash':
                setValue("buyersDownPaymentPct", 100);
                setValue("buyersDownPayment", currency(watchPurchasePrice, { separator: ',', symbol: '' }).format());
                break;
          case 'EightyTwenty':
                setValue("buyersDownPaymentPct", 0);
                setValue("buyersDownPayment", currency(0));
                setValue("buyersLoanAmount1", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.8).format());
                setValue("buyersLoanAmount2", currency(watchPurchasePrice, { separator: ',', symbol: '' }).multiply(0.2).format());
                break;
          default:
                break;
        }
    }

    useEffect(() => {
        updateBasedOnLoanType();
    }, [watchLoanType]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);
        
    const validateNumberFieldsHaveValues = (f) => {
        var numberFields = ['interestRate1', 'interestRate2', 'buyersLoanAmount1', 'buyersLoanAmount2', 'term1', 'term2', 'purchasePrice', 'buyersDownPayment', 'annualTaxes'];
        for (const field in f) {
            if (numberFields.includes(field)) { 
                f[field] = currency(f[field]).value;
            }
        }
        return f;
    }

    const onSubmit = (formVals, e) => {
        if (!selectedCounty) return;
        var stateAndCounty = {
            county: selectedCounty.county,
            stateAbbr: selectedCounty.state
        };
        formVals = { ...formVals, propertyCounty: stateAndCounty };
        formVals = validateNumberFieldsHaveValues(formVals);
        if (saving) {
            setCommonData(formVals)
        } else { 
            submitData(formVals, e.nativeEvent.submitter.id === 'calculateBuyers' ? 'buyer' : 'seller')
        }
    };

    useEffect(() => {
        if (!selectedCounty || !paramParcelId) return;
        var property = {
            parcelId: paramParcelId, 
            siteState: selectedCounty.state
            }
        calculatePropertyTax(property, selectedCounty.countyValue);
    }, [selectedCounty, paramParcelId]);

    function doSearch() {
        setNoResults(false);
        setMoreResults(false);
        setPropertyInfo(null);
        if (watchAddress === null || selectedCounty === null) {
            return;
        }
        if (selectedCounty &&  watchAddress !== "") {
            setLoadingSearchResults(true);
            var searchCriteria = {
                address: watchAddress,
                county: selectedCounty.county,
                state: selectedCounty.state
            };
            getSearchResults(searchCriteria);
        }
    }

    function calculatePropertyTax(property, county) {
        setLoadingPropertyResults(true);
        setPropertyInfo(null);
        apiTools.get(`/property-profile?state=${property.siteState}&county=${county}&parcelId=${property.parcelId}`)
            .then((response) => {
                setPropertyInfo(response.data);
                setValue('city', response.data.siteCity);
                setValue('propertyAddress', response.data.siteAddress);
                setValueCustom('annualTaxes', response.data.taxTotal1);  
                if (watchPurchasePrice && currency(watchPurchasePrice).value !== currency(0).value) {
                    var taxesPct = (response.data.taxTotal1 / currency(watchPurchasePrice).value) * 100;
                    if (watchAnnualTaxesPct !== taxesPct) setValueCustom("annualTaxesPct", parseFloat(taxesPct.toFixed(3)));
                }

                setLoadingPropertyResults(false);
            });
    }
    const getSearchResults = async (searchCriteria) => {
        apiTools.get(`/property-search?state=${searchCriteria.state}&county=${searchCriteria.county}&searchType=siteAddr&value=${searchCriteria.address}`)
            .then((response) => {
                setSearchResults(response.data.records);
                if (response.data.records.length === 0) {
                    setNoResults(true);
                } else if (response.data.records.length === 1) { 
                    calculatePropertyTax(response.data.records[0], searchCriteria.county);
                } else if (response.data.totalCount > response.data.currentCount) {
                    setMoreResults(true);
                }
                setLoadingSearchResults(false);
            });
    }

    const handleAddressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            doSearch();
        }
    }
    
    const validatePurchasePrice = () => { 
        var max = (countySettings) ? Number(countySettings.maxPurchasePriceForOnlineRateDisclosure) : 5;
        return currency(watchPurchasePrice).value < max;
    }
    return (
        <div className={className}>      
            <div className="form-labels-left-md">
                <form onSubmit={handleSubmit(onSubmit)} onChange={e => setChangedElement(e.target)} onBlur={e => formatInput(e) } onFocus={(e) => clearZero(e)}>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="buyerName">Buyer Name(s):</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="buyerName" type="text" id="buyerName" className="form-control" {...register("buyerName")} />
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="sellerName">Seller Name(s):</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="sellerName" type="text" id="sellerName" className="form-control" {...register("sellerName")} />
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="preparedBy">Prepared By:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="preparedBy" type="text" id="preparedBy" className="form-control" {...register("preparedBy")} />
                        </div>
                    </div>
                    <div className="row pt-1">
                    <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="county">County:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <CountySelector regionId={activeRegion.regionId} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} toolName="NetSheet" toolRoute="net-sheet" loadedCounty={loadedNetSheet ? loadedNetSheet.netSheetFormData.county : null} paramCounty={paramCounty} />
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="propertyAddress">Property Address:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="propertyAddress" type="text" id="propertyAddress" className="form-control" {...register("propertyAddress")} onKeyDown={(e) => handleAddressEnter(e)} />
                            {selectedCounty && selectedCounty.taxLookupEnabled && <input type="button" className="btn btn-primary mt-1" value="Search" onClick={() => doSearch()} />}
                        </div>
                    </div>
                    {noResults && <div className="row offset-0 offset-lg-2"><div className="col-12 fw-bold">No results were found for {watchAddress}.</div></div>}
                    {!loadingSearchResults && searchResults.length > 0 && !loadingPropertyResults && propertyInfo === null &&
                        <>
                            {searchResults.length > 1 && <div className="row offset-0 offset-lg-2"><div className="col-12 fw-bold">Multiple properties were found. Please select a property:</div></div>}
                            {searchResults.length === 1 && <div className="row offset-0 offset-lg-2"><div className="col-12 fw-bold">Please confirm this is the property you are looking for:</div></div>}
                            <div className="row offset-0 offset-lg-2">
                                <div className="col-5 col-lg-4 fw-bold">Address</div>
                                <div className="col-5 col-lg-4 fw-bold">Owner</div>
                                <div className="col-2"></div>
                                <div className="col-0 col-lg-2"></div>
                            </div>
                            {selectedCounty && orderBy(searchResults, ['siteAddress']).map((p, idx) =>
                                <div className="row offset-0 offset-lg-2 pb-2 no-localization" key={idx}>
                                    <div className="col-5 col-lg-4 lg-sm no-localization">{p.siteAddress}</div>
                                    <div className="col-5 col-lg-4 lg-sm no-localization">{p.ownerName}</div>
                                    <div className="col-2 p-0">
                                        <button className="btn btn-primary btn-sm" onClick={() => calculatePropertyTax(p, selectedCounty.county)}>Select</button>
                                    </div>
                                    <div className="col-0 col-lg-2"></div>
                                </div>)
                            }
                            {moreResults && <div className="row offset-0 offset-lg-2"><div className="col-12 fw-bold">More than {searchResults.length} properties were found, please enter a more precise address.</div></div>}

                        </>
                    }   
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="city">Property City:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="city" type="text" id="city" className="form-control" {...register("city", { required: true })} />
                            {errors?.city?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="annualTaxes">Annual Taxes:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control clear-zero currency" id="annualTaxes" name="annualTaxes" defaultValue="0.00" type="text" {...register("annualTaxes", { required: true, min: 0 })} />
                            </div>
                            {errors?.annualTaxes?.type === "required" && <small className="text-danger">This field is required</small>}
                            {errors?.annualTaxes?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-6 offset-md-4">
                            <div className="input-group">
                                <span className="input-group-text">=</span>
                                <input className="form-control clear-zero" id="annualTaxesPct" name="annualTaxesPct" max="100" defaultValue="0.00" type="text" {...register("annualTaxesPct", { required: true, min: 0, max: 100 })} />
                                <span className="input-group-text">%</span>
                            </div>
                            {errors?.annualTaxesPct?.type === "required" && <small className="text-danger">This field is required</small>}
                            {errors?.annualTaxesPct?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                            {propertyInfo && (selectedCountyState === "MT") && <div className="small">May be subject to additional city taxes.</div>}
                        </div>
                    </div>

                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="closingDate">Closing Date:</label>
                        </div>
                        <div className="col-12 col-md-6">
                        <input name="closingDate" type="date" id="closingDate" defaultValue={DateTime.now().toFormat('yyyy-MM-dd')} className="form-control" {...register("closingDate", { required: true })} />
                        </div>
                        {errors?.closingDate?.type === "required" && <small className="text-danger">This field is required</small>}
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="purchasePrice">Purchase Price:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control clear-zero currency" id="purchasePrice" name="purchasePrice" defaultValue="0.00" type="text" {...register("purchasePrice", { required: true, min: 0, validate: { maxPurchasePrice: () => validatePurchasePrice() } } )} />
                            </div>
                            {errors?.purchasePrice?.type === "required" && <small className="text-danger">This field is required</small>}
                            {errors?.purchasePrice?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                            {errors?.purchasePrice?.type === "maxPurchasePrice" && <small className="text-danger">Please give us a call for a custom quote.</small>}
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="loanType">Loan Type:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <select className="form-select no-localization" name="loanType" id="loanType" {...register("loanType")}>
		                        <option value="Conventional">Conventional</option>
		                        <option value="FHA">FHA</option>
		                        <option value="IHFA">IHFA</option>
		                        <option value="VA">VA</option>
		                        <option value="ZeroDown">0-Down</option>
		                        <option value="SellerCarryBack">Seller Carry Back</option>
		                        <option value="Cash">Cash</option>
		                        <option value="EightyTwenty">80/20</option>
                            </select>
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="buyersDownPayment">Buyer's Down Payment:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control clear-zero currency" id="buyersDownPayment" disabled={watchLoanType === 'EightyTwenty'} name="buyersDownPayment" defaultValue="0.00" type="text" {...register("buyersDownPayment", { min: 0 })} />
                            </div>
                            {errors?.buyersDownPayment?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-12 col-md-6 offset-md-4">
                            <div className="input-group">
                                <span className="input-group-text">=</span>
                                <input className="form-control clear-zero" id="buyersDownPaymentPct"  disabled={watchLoanType === 'EightyTwenty'} name="buyersDownPaymentPct" defaultValue="0.00" type="text" {...register("buyersDownPaymentPct", { min: 0 })} />
                                <span className="input-group-text">%</span>
                            </div>
                            {errors?.buyersDownPaymentPct?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label>1st Loan (80%):</label>
                        </div>
                    </div>
                    <div className={watchLoanType !== 'Cash' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="buyersLoanAmount1">Loan Amount:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control clear-zero currency" id="buyersLoanAmount1" name="buyersLoanAmount1" disabled={watchLoanType === 'EightyTwenty'} defaultValue="0.00" type="text" {...register("buyersLoanAmount1", {  min: 0, validate: { lessThanPurchasePrice: (value) => currency(value).value <= currency(watchPurchasePrice).value } })} />
                            </div>
                            {errors?.buyersLoanAmount1?.type === "required" && <small className="text-danger">This field is required</small>}
                            {errors?.buyersLoanAmount1?.type === "lessThanPurchasePrice" && <small className="text-danger">NOTE: Loan amount is greater than the purchase price. Please call for a quote.</small>}
                            {errors?.buyersLoanAmount1?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className={watchLoanType !== 'Cash' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="interestRate1">Interest Rate:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <input className="form-control clear-zero" id="interestRate1" name="interestRate1" defaultValue="0.00" type="number" step="0.01" {...register("interestRate1", { min: 0 })} />
                                <span className="input-group-text">%</span>
                            </div>
                            {errors?.interestRate1?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className={watchLoanType !== 'Cash' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="term1">Term (in months):</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="term1" type="text" id="term1" defaultValue="360" className="form-control" {...register("term1", { min: 1, required: true })} />
                            {errors?.term1?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                            {errors?.term1?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                    </div>
                    <div className={watchLoanType !== 'Cash' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="compoundingInterval1">Compounding Interval:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <select className="form-select" name="compoundingInterval1" id="compoundingInterval1" {...register("compoundingInterval1")}>
				                <option value="Monthly">Monthly</option>
				                <option value="Quarterly">Quarterly</option>
				                <option value="SemiAnnually">Semi-Annually</option>
				                <option value="Annually">Annually</option>
                            </select>
                        </div>
                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label>2nd Loan (20%):</label>
                        </div>
                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="buyersLoanAmount2">Loan Amount:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control clear-zero currency" id="buyersLoanAmount2" name="buyersLoanAmount2" disabled={watchLoanType === 'EightyTwenty'} defaultValue="0.00" type="text" {...register("buyersLoanAmount2", {  min: 0, validate: { lessThanPurchasePrice: (value) => currency(value).value <= currency(watchPurchasePrice).value } })} />
                            </div>
                            {errors?.buyersLoanAmount2?.type === "required" && <small className="text-danger">This field is required</small>}
                            {errors?.buyersLoanAmount2?.type === "lessThanPurchasePrice" && <small className="text-danger">NOTE: Loan amount is greater than the purchase price. Please call for a quote.</small>}
                            {errors?.buyersLoanAmount2?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="interestRate2">Interest Rate:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <input className="form-control clear-zero" id="interestRate2"  name="interestRate2" defaultValue="0.00" type="number" step="0.01" {...register("interestRate2", { min: 0 })} />
                                <span className="input-group-text">%</span>
                            </div>
                            {errors?.interestRate2?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                        </div>
                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold required">
                            <label htmlFor="term2">Term (in months):</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <input name="term2" type="text" id="term2" defaultValue="360" className="form-control" {...register("term2", { min: 1, required: true })} />
                            {errors?.term2?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                            {errors?.term2?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>

                    </div>
                    <div className={watchLoanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                        <div className="col-12 col-md-4 form-label fw-bold">
                            <label htmlFor="compoundingInterval2">Compounding Interval:</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <select className="form-select" name="compoundingInterval2" id="compoundingInterval2" {...register("compoundingInterval2")}>
				                <option value="Monthly">Monthly</option>
				                <option value="Quarterly">Quarterly</option>
				                <option value="SemiAnnually">Semi-Annually</option>
				                <option value="Annually">Annually</option>
                            </select>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col d-flex justify-content-around flex-wrap">
                            <input type="submit" id="calculateBuyers" value="View buyer's closing costs" className="btn btn-primary mt-1" onClick={() => EventTrack("Net Sheet", "View buyer's results", "Button")} />
                            <input type="submit" id="calculateSellers" value="View seller's closing costs" className="btn btn-primary mt-1" onClick={() => EventTrack("Net Sheet", "View seller's results", "Button")} />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default NetSheetCommonData;