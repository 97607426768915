import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';


const SellerSideCommissions = ({data, setCommissionsTotal, className, saving, setSellersCommissions, loadedNetSheet}) => {
    const { register, setValue, getValues, watch, handleSubmit } = useForm();
    const [changedElement, setChangedElement] = useState(null);
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    /* watchers */
    const watchSellerSellingCommissionPercentage = watch("sellingSidePercentage");
    const watchSellerListingCommissionPercentage = watch("listingSidePercentage");
    const watchSellerSellingCommissionAmount = watch("sellingSideFee");
    const watchSellerListingCommissionAmount = watch("listingSideFee");
    const watchSellerSellingCommissionFlatFee = watch("sellingSideFlatFee");
    const watchSellerListingCommissionFlatFee = watch("listingSideFlatFee");
    const watchTotalCommissions= watch("totalCommissions");

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.sellersCommissions)) {
                if (newArr.includes(key)) {
                    currency(value).value > 0 ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);
                    switchChangedElement(key, true);
                }
            }
            setManuallyChangedInputs(newArr);
            setDefaults(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data) return;
            setDefaults();
        }
    }, [loadedNetSheet, data]);

    const setDefaults = (autofillValues) => {
        if ((autofillValues && alreadyLoadedNetSheet) || !autofillValues) {
            if (typeof (watchSellerSellingCommissionPercentage) === 'undefined') {
                var startingCommission = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(0.03).format();
                setValue('sellingSidePercentage', 3.00);
                setValue('sellingSideFee', startingCommission);
                setValue('listingSidePercentage', 3.00);
                setValue('listingSideFee', startingCommission);
            } else {
                if (parseFloat(watchSellerSellingCommissionFlatFee) === 0) {
                    var startingSellingCommission = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(parseFloat(watchSellerSellingCommissionPercentage) / 100).format();
                    setValue('sellingSideFee', startingSellingCommission);
                }
                if (parseFloat(watchSellerListingCommissionFlatFee) === 0) {
                    var startingListingCommission = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(parseFloat(watchSellerListingCommissionPercentage) / 100).format();
                    setValue('listingSideFee', startingListingCommission);
                }
            }
        }
        calculateSum(getValues("sellingSideFee"), getValues("sellingSideFlatFee"), getValues("listingSideFee"), getValues("listingSideFlatFee"));
    }

    const setManuallyChanged = (e) => {
        if (!manuallyChangedInputs.includes(e.target.id)) {
            setManuallyChangedInputs([...manuallyChangedInputs, e.target.id])           
        }
    }

    const calculateSum = (sellingPctAmount, sellingFlatFee, listingPctAmount, listingFlatFee ) => { 
        const sum = currency(sellingPctAmount, { separator: ',', symbol: '' })
            .add(sellingFlatFee)
            .add(listingPctAmount)
            .add(listingFlatFee)
            .format();
        setValueCustom("totalCommissions", sum);
        setCommissionsTotal(sum);
    }


    const switchChangedElement = (id, fromLoaded) => { 
        var sellerSellingCommissionPercentage = fromLoaded ? loadedNetSheet.sellersCommissions.sellingSidePercentage : watchSellerSellingCommissionPercentage;
        var sellerListingCommissionAmount = fromLoaded ? loadedNetSheet.sellersCommissions.sellingSideFee : watchSellerListingCommissionAmount;
        var sellerListingCommissionFlatFee = fromLoaded ? loadedNetSheet.sellersCommissions.sellingSideFlatFee : watchSellerListingCommissionFlatFee;
        var sellerSellingCommissionAmount = fromLoaded ? loadedNetSheet.sellersCommissions.listingSideFee : watchSellerSellingCommissionAmount;
        var sellerSellingCommissionFlatFee = fromLoaded ? loadedNetSheet.sellersCommissions.listingSideFlatFee : watchSellerSellingCommissionFlatFee;
        var sellerListingCommissionPercentage = fromLoaded ? loadedNetSheet.sellersCommissions.listingSidePercentage :watchSellerListingCommissionPercentage;
        var amount = 0;
        switch (id) {
            case 'sellingSidePercentage':
                amount = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(sellerSellingCommissionPercentage / 100).format();
                setValueCustom('sellingSideFee', amount);
                if (currency(sellerSellingCommissionPercentage).value !== 0) {
                    setValueCustom("sellingSideFlatFee", 0);
                    calculateSum(amount, 0, sellerListingCommissionAmount, sellerListingCommissionFlatFee);
                } else {
                    setValueCustom("sellingSideFee", 0);
                    calculateSum(amount, sellerSellingCommissionFlatFee, sellerListingCommissionAmount, sellerListingCommissionFlatFee);
                }
                break;
            case 'listingSidePercentage':
                amount = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(sellerListingCommissionPercentage / 100).format();
                setValueCustom('listingSideFee', amount);
                if (currency(sellerListingCommissionPercentage).value !== 0) { 
                    setValueCustom("listingSideFlatFee", 0);
                    calculateSum(sellerSellingCommissionAmount, sellerSellingCommissionFlatFee, amount, 0);
                } else {
                    setValueCustom("listingSideFee", 0);
                    calculateSum(sellerSellingCommissionAmount, sellerSellingCommissionFlatFee, amount, sellerListingCommissionFlatFee);
                }
                break;
            case 'sellingSideFlatFee':
                if (currency(sellerSellingCommissionFlatFee).value !== 0) { 
                    setValueCustom("sellingSidePercentage", 0);
                    setValueCustom("sellingSideFee", 0);
                    calculateSum(0, sellerSellingCommissionFlatFee, sellerListingCommissionAmount, sellerListingCommissionFlatFee);
                } else {
                    calculateSum(sellerSellingCommissionAmount, 0, sellerListingCommissionAmount, sellerListingCommissionFlatFee);
                }
                break;            
            case 'listingSideFlatFee':
                if (currency(sellerListingCommissionFlatFee).value !== 0) { 
                    setValueCustom("listingSidePercentage", 0)
                    setValueCustom("listingSideFee", 0)
                    calculateSum(sellerSellingCommissionAmount, sellerSellingCommissionFlatFee, 0, sellerListingCommissionFlatFee);
                } else {
                    calculateSum(sellerSellingCommissionAmount, sellerSellingCommissionFlatFee, sellerListingCommissionAmount, 0);
                }                
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!changedElement) return;
        switchChangedElement(changedElement.id, false);
    }, [watchSellerSellingCommissionPercentage, watchSellerListingCommissionPercentage, watchSellerSellingCommissionFlatFee ,watchSellerListingCommissionFlatFee]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setSellersCommissions({formVals, manuallyChangedInputs});
    };

    const setValueCustom = (el, val) => { 
        if (changedElement && changedElement.id === el) return;
        setValue(el, currency(val , { separator: ',', symbol: '' }).format());
    }

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideCommissions" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Commissions</div>
                    <div className="col text-end">Total: {currency(watchTotalCommissions, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onChange={e => setChangedElement(e.target)} onBlur={e => { formatInput(e); setManuallyChanged(e) }} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideCommissions">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className="col-12 fw-bold">Real Estate Fee (Selling Side):</div>
                            </div> 
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellingSidePercentage">Percentage:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <input name="sellingSidePercentage" type="number" defaultValue="3.00" id="sellingSidePercentage" className="form-control clear-zero" step="0.01" {...register("sellingSidePercentage")}/>
                                        <span className="input-group-text">%</span>
                                        <span className="input-group-text">$</span>
                                        <input name="sellingSideFee" type="text" defaultValue="0.00" readOnly="readonly" id="sellingSideFee" tabIndex="-1" className="form-control" {...register("sellingSideFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellingSideFlatFee">Flat Fee:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="sellingSideFlatFee" type="text" defaultValue="0.00" id="sellingSideFlatFee" className="form-control clear-zero currency"  {...register("sellingSideFlatFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 fw-bold">Real Estate Fee (Listing Side):</div>
                            </div> 
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="listingSidePercentage">Percentage:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <input name="listingSidePercentage" type="number" defaultValue="3.00" id="listingSidePercentage" className="form-control clear-zero" step="0.01" {...register("listingSidePercentage")}/>
                                        <span className="input-group-text">%</span>
                                        <span className="input-group-text">$</span>
                                        <input name="listingSideFee" type="text" defaultValue="0.00" readOnly="readonly" id="listingSideFee" tabIndex="-1" className="form-control"  {...register("listingSideFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="listingSideFlatFee">Flat Fee:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="listingSideFlatFee" type="text" defaultValue="0.00" id="listingSideFlatFee" className="form-control clear-zero currency"  {...register("listingSideFlatFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalCommissions">Total Commissions:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalCommissions" type="text" defaultValue="0.00"  readOnly="readonly" id="totalCommissions" tabIndex="-1" className="form-control" {...register("totalCommissions")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideCommissions;