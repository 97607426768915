import React, { useRef, useState, useEffect } from 'react';
import currency from 'currency.js';
import SellerSideCommonData from './seller-side-common-data';
import SellerSideClosingCosts from './seller-side-closing-costs';
import SellerSideMiscellaneous from './seller-side-miscellaneous';
import SellerSideTotals from './seller-side-totals';
import SellerSideMortgageInformation from './seller-side-mortgage-information';
import SellerSideCommissions from './seller-side-commissions';

const SellerSide = ({ data, className, saving, setSellersData, setMiscOpposites, miscData, loadedNetSheet }) => {
    const headingRef = useRef(null);
    const [loansPaidOffCount, setLoansPaidOffCount] = useState(0);
    const [closingCostsTotal, setClosingCostsTotal] = useState(0);
    const [mortgageInformationTotal, setMortgageInformationTotal] = useState(0);
    const [commissionsTotal, setCommissionsTotal] = useState(null);
    const [miscellaneousTotal, setMiscellaneousTotal] = useState(0);
    const [sellersTotals, setSellersTotals] = useState(null);
    const [totalSellingExpenses, setTotalSellingExpenses] = useState(0);

    const [sellersClosingCosts, setSellersClosingCosts] = useState(null);
    const [sellersMortgageInformation, setSellersMortgageInformation] = useState(null);
    const [sellersCommissions, setSellersCommissions] = useState(null);
    const [sellersMiscellaneous, setSellersMiscellaneous] = useState(null);


    useEffect(() => { 
        if (!sellersMortgageInformation || !sellersClosingCosts || !sellersCommissions || !sellersMiscellaneous || !sellersTotals) return;
        var obj = { sellersMortgageInformation: { ...sellersMortgageInformation }, 
            sellersClosingCosts: { ...sellersClosingCosts.formVals }, 
            sellersCommissions: { ...sellersCommissions.formVals }, 
            sellersMiscellaneousChargesAndCredits: { ...sellersMiscellaneous.formVals }, 
            ...sellersTotals,
            ManuallyChangedValues: [...new Set([...sellersClosingCosts.manuallyChangedInputs, ...sellersCommissions.manuallyChangedInputs, ...sellersMiscellaneous.manuallyChangedInputs])]
        };
        setSellersData(obj);
    }, [sellersCommissions, sellersClosingCosts, sellersMortgageInformation, sellersMiscellaneous, sellersTotals])

    useEffect(() => { 
        if (!data) return;
        const sum = currency(closingCostsTotal, { separator: ',', symbol: '' })
            .add(commissionsTotal)
            .add(miscellaneousTotal)
            .add(mortgageInformationTotal)
            .value;
        setTotalSellingExpenses(sum);
    }, [closingCostsTotal, mortgageInformationTotal, miscellaneousTotal, commissionsTotal, data])


    return (
        <div className={className}>      
            <h1 ref={headingRef}>Seller's Net Sheet</h1>
            <SellerSideCommonData data={data} className="mb-2 accordion"/>
            <SellerSideMortgageInformation data={data} setMortgageInformationTotal={setMortgageInformationTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setSellersMortgageInformation={setSellersMortgageInformation} setLoansPaidOffCount={setLoansPaidOffCount} />
            <SellerSideClosingCosts data={data} setClosingCostsTotal={setClosingCostsTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setSellersClosingCosts={setSellersClosingCosts} loansPaidOffCount={loansPaidOffCount} />
            <SellerSideCommissions data={data} setCommissionsTotal={setCommissionsTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setSellersCommissions={setSellersCommissions} />
            <SellerSideMiscellaneous data={data} setMiscellaneousTotal={setMiscellaneousTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet}
                setSellersMiscellaneous={setSellersMiscellaneous} setMiscOpposites={setMiscOpposites} miscData={miscData} />
            <SellerSideTotals data={data} total={totalSellingExpenses} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setSellersTotals={setSellersTotals}/>
        </div>
    );
}

export default SellerSide;