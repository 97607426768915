import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { capitalizeFirstLetter, clearZero, formatInput } from '../utilities';

const SellerSideClosingCosts = ({ data, setClosingCostsTotal, className, saving, setSellersClosingCosts, loansPaidOffCount, loadedNetSheet }) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */
    const watchSellerDocPrep = watch("documentationPreparationFee");
    const watchSellerReleaseServiceFee = watch("totalReleaseServiceFees");
    const watchSellerOwnersTitlePolicy = watch("ownersTitlePolicy");
    const watchSellerOwnersTitlePolicySalesTax = watch("ownersTitlePolicySalesTax");
    const watchSellerHomeOwnersCoverageOnePolicy = watch("homeownersCoverageOnePolicy");
    const watchSellerHomeOwnersCoverageOnePolicySalesTax = watch("homeownersCoverageOnePolicySalesTax");
    const watchSellerSurveyFee = watch("surveyFee");
    const watchSellerEscrowFee = watch("escrowFee");
    const watchSellerEscrowFeeSalesTax = watch("escrowFeeSalesTax");
    const watchSellerDiscountPointsDollars = watch("discountDollars");
    const watchSellerTransferFee = watch("transferFee");
    const watchSellerFloodCertification = watch("floodCertificationFee");
    const watchSellerTaxServiceFee = watch("taxServiceFee");
    const watchSellersThirdPartyServiceFee = watch("sellersThirdPartyServiceFee");
    const watchStateTechnologyFee = watch("stateTechnologyFee");
    const watchStateExciseFee = watch("stateExciseFee");
    const watchCityExciseFee = watch("cityExciseFee");
    const watchDiscountPoints = watch("discountPoints");
    const watchSellerAppraisalFee = watch("appraisalFee");
    const watchWireFee = watch("wireFee");
    const watchSellerTotalClosingCosts = watch("sellerTotalClosingCosts");

    /* state vars */
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.sellersClosingCosts)) {
                var sellerKey = 'seller' + capitalizeFirstLetter(key);
                if (newArr.includes(sellerKey)) {
                    currency(value).value > 0 ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);
                }
            }
            setManuallyChangedInputs(newArr);
            setDefaults(newArr, true);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data) return;
            setDefaults(manuallyChangedInputs, false);
        }
    }, [loadedNetSheet, data]);

    const setDefaults = (manuallyChanged, fromLoaded) => { 
        for (const [key, value] of Object.entries(data.sellersClosingCosts)) {
            var sellerKey = 'seller' + capitalizeFirstLetter(key);
            if (!manuallyChanged.includes(sellerKey) && sellerKey !== 'sellerTotalClosingCosts' && !key.includes('SalesTax')) {
                setValue(key, currency(value, { separator: ',', symbol: '' }).format());
            }
        }
    }

    useEffect(() => {
        const sum = currency(watchSellerDocPrep, { separator: ',', symbol: '' })
            .add(watchSellerReleaseServiceFee)
            .add(watchSellerOwnersTitlePolicy)
            .add(watchSellerOwnersTitlePolicySalesTax)
            .add(watchSellerHomeOwnersCoverageOnePolicy)
            .add(watchSellerHomeOwnersCoverageOnePolicySalesTax)
            .add(watchSellerSurveyFee)
            .add(watchSellerEscrowFee)
            .add(watchSellerEscrowFeeSalesTax)
            .add(watchSellerDiscountPointsDollars)
            .add(watchSellerTransferFee)
            .add(watchSellerFloodCertification)
            .add(watchSellerTaxServiceFee)
            .add(watchSellersThirdPartyServiceFee)
            .add(watchStateTechnologyFee)
            .add(watchStateExciseFee)
            .add(watchCityExciseFee)
            .add(watchWireFee)
            .add(watchSellerAppraisalFee).format();
        setValue("sellerTotalClosingCosts", sum);
        setClosingCostsTotal(sum);
    }, [watchSellerDocPrep, watchSellerReleaseServiceFee,watchSellerOwnersTitlePolicy, watchSellerOwnersTitlePolicySalesTax, watchSellerSurveyFee,
        watchSellerEscrowFee, watchSellerEscrowFeeSalesTax, watchSellerDiscountPointsDollars, watchSellerTransferFee ,watchSellerFloodCertification, 
        watchSellerAppraisalFee, watchSellerHomeOwnersCoverageOnePolicy, watchSellerHomeOwnersCoverageOnePolicySalesTax, watchSellerTaxServiceFee, 
        watchSellersThirdPartyServiceFee, watchStateTechnologyFee, watchStateExciseFee, watchCityExciseFee, watchWireFee]);

    useEffect(() => {
        if (!data) return;
        setValue('discountDollars', currency(data.netSheetFormData.buyersLoanAmount1, { separator: ',', symbol: '' }).divide(100).multiply(watchDiscountPoints).format());
    }, [watchDiscountPoints, data])

    useEffect(() => {
        if (!data || loadedNetSheet && loadedNetSheet.manuallyChangedValues.includes("sellerTotalReleaseServiceFees")) return;
        setValue('totalReleaseServiceFees', currency(data.sellersClosingCosts.releaseServiceFeePerLoan, { separator: ',', symbol: '' }).multiply(loansPaidOffCount).format());
    }, [loansPaidOffCount, data])

    const setManuallyChanged = (e) => {
        var sellerKey = 'seller' + capitalizeFirstLetter(e.target.id);
        for (const [key, value] of Object.entries(data.sellersClosingCosts)) {
            if (key === e.target.id
                && currency(value, { separator: ',', symbol: '' }).format() !== e.target.value
                && !manuallyChangedInputs.includes(sellerKey)) {
                setManuallyChangedInputs([...manuallyChangedInputs, sellerKey])
            }
        }
    }

    useEffect(() => { 
        setSalesTax("escrowFee", watchSellerEscrowFee);
    },[watchSellerEscrowFee, data])

    useEffect(() => { 
        setSalesTax("ownersTitlePolicy", watchSellerOwnersTitlePolicy);
    },[watchSellerOwnersTitlePolicy, data])

    useEffect(() => { 
        setSalesTax("homeownersCoverageOnePolicy", watchSellerHomeOwnersCoverageOnePolicy);
    },[watchSellerHomeOwnersCoverageOnePolicy, data])

    const setSalesTax = (id, val) => {
        if (!data) return;
        var salesTaxId = id + 'SalesTax';
        setValue(salesTaxId, currency(val, { separator: ',', symbol: '' }).multiply(data.salesTaxRate).format())
        if (!manuallyChangedInputs.includes(salesTaxId)) 
        {
            setManuallyChangedInputs([...manuallyChangedInputs, salesTaxId])
        }
    }

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setSellersClosingCosts({formVals, manuallyChangedInputs});
    };

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideClosingCosts" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Closing Costs</div>
                    <div className="col text-end">Total: {currency(watchSellerTotalClosingCosts, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onBlur={e => { formatInput(e); setManuallyChanged(e) }} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideClosingCosts">
                        <div className="accordion-body">
                            <div className="row row-cols-1 row-cols-sm-2">
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="documentationPreparationFee">Doc Prep:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="documentationPreparationFee" type="text" defaultValue="0.00" maxLength="8" id="documentationPreparationFee" className="form-control clear-zero currency" {...register("documentationPreparationFee")}/>
                                        </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="totalReleaseServiceFees">Release Services Fees:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="totalReleaseServiceFees" type="text" defaultValue="0.00" maxLength="8" id="totalReleaseServiceFees" className="form-control clear-zero currency"  {...register("totalReleaseServiceFees")}/>
                                            </div>
                                            <span className="text-danger">Note: {currency(data.sellersClosingCosts.releaseServiceFeePerLoan, { separator: ',', symbol: '$' }).format()} per loan paid off</span>
                                        </div>
                                    </div>
                                </div>
                                                    
                                <div className={data.netSheetFormData.propertyCounty.stateAbbr !== 'WA' ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="ownersTitlePolicy">Owner's Title Policy:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                                <input name="ownersTitlePolicy" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="ownersTitlePolicy" className="form-control clear-zero currency"  {...register("ownersTitlePolicy")} />
                                            </div>
                                            {currency(watchSellerOwnersTitlePolicy).value > 1000000 && <span className="text-danger">Please call for a quote.</span>}                   
                                        </div>
                                    </div>
                                </div>

                                <div className={data.netSheetFormData.propertyCounty.stateAbbr !== 'WA' && currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="ownersTitlePolicySalesTax">Owner's Title Policy Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="ownersTitlePolicySalesTax" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="ownersTitlePolicySalesTax" className="form-control clear-zero currency"  {...register("ownersTitlePolicySalesTax")}/>
                                            </div>
                                                                
                                        </div>
                                    </div>
                                </div>
                                <div className={!data.buyerPaysHomeOwnersCoverageOnePolicy ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="homeownersCoverageOnePolicy">Homeowner's Policy (CoverageOne):</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="homeownersCoverageOnePolicy" type="text" defaultValue="0.00" maxLength="8" id="sellersHomeOwnerCoverageOnePolicy" className="form-control hint clear-zero currency"  {...register("homeownersCoverageOnePolicy")} />
                                            </div>
                                            {currency(watchSellerHomeOwnersCoverageOnePolicy).value > 1000000 && <span className="text-danger">Please call for a quote.</span>}                   
                                        </div>
                                    </div>
                                </div>

                                <div className={!data.buyerPaysHomeOwnersCoverageOnePolicy && currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="homeownersCoverageOnePolicySalesTax">Homeowner's Policy Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="homeownersCoverageOnePolicySalesTax" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="homeownersCoverageOnePolicySalesTax" className="form-control hint clear-zero currency"  {...register("homeownersCoverageOnePolicySalesTax")}/>
                                            </div>
                                                                
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="discountPoints">Discount Points:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">#</span>
                                                <input name="discountPoints" type="number" step="1" defaultValue="0" id="discountPoints" className="form-control" {...register("discountPoints")}/>
                                                <span className="input-group-text">$</span>
                                                <input name="discountDollars" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="discountDollars" className="form-control" {...register("discountDollars")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="escrowFee">Escrow Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="escrowFee" type="text" defaultValue="0.00" maxLength="8" id="escrowFee" className="form-control clear-zero currency"  {...register("escrowFee")}/>
                                        </div></div>
                                    </div>
                                </div>
                                <div className={currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="escrowFeeSalesTax">Escrow Fee Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">

                                            <span className="input-group-text">$</span>
                                            <input name="escrowFeeSalesTax" type="text" defaultValue="0.00" maxLength="8" id="escrowFeeSalesTax" readOnly="readonly" className="form-control clear-zero currency"  {...register("escrowFeeSalesTax")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="transferFee">Transfer Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="transferFee" type="text" defaultValue="0.00" maxLength="8" id="transferFee" className="form-control clear-zero currency"  {...register("transferFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="surveyFee">Survey:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="surveyFee" type="text" defaultValue="0.00" maxLength="8" id="surveyFee" className="form-control clear-zero currency"  {...register("surveyFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="appraisalFee">Appraisal Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="appraisalFee" type="text" defaultValue="0.00" maxLength="8" id="appraisalFee" className="form-control clear-zero currency"  {...register("appraisalFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeSellerFloodCertFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="floodCertificationFee">Flood Cert Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="floodCertificationFee" type="text" defaultValue="0.00" maxLength="8" id="floodCertificationFee" className="form-control clear-zero currency"  {...register("floodCertificationFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeSellerTaxServiceFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="taxServiceFee">Tax Service Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="taxServiceFee" type="text" defaultValue="0.00" maxLength="8" id="taxServiceFee" className="form-control clear-zero currency"  {...register("taxServiceFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeSellerThirdPartyServiceFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="sellersThirdPartyServiceFee">3rd Party Service Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="sellersThirdPartyServiceFee" type="text" defaultValue="0.00" maxLength="8" id="sellersThirdPartyServiceFee" className="form-control clear-zero currency"  {...register("sellersThirdPartyServiceFee")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeStateTechFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="stateTechnologyFee">State Technology Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="stateTechnologyFee" type="text" defaultValue="0.00" maxLength="8" id="stateTechnologyFee" className="form-control clear-zero currency"  {...register("stateTechnologyFee")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={currency(data.sellersClosingCosts.stateExciseFee).value > 0 ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="stateExciseFee">State Excise Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="stateExciseFee" type="text" readOnly="readonly" defaultValue="0.00" maxLength="8" id="stateExciseFee" className="form-control clear-zero currency"  {...register("stateExciseFee")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={currency(data.sellersClosingCosts.cityExciseFee).value > 0 ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="cityExciseFee">City Excise Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="cityExciseFee" type="text" readOnly="readonly" defaultValue="0.00" maxLength="8" id="cityExciseFee" className="form-control clear-zero currency"  {...register("cityExciseFee")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="wireFee">Wire Fee*:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="wireFee" type="text" defaultValue="0.00" maxLength="8" id="wireFee" className="form-control clear-zero currency"  {...register("wireFee")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                    <div className="col-12 col-sm-4 form-label fw-bold">
                                        <label htmlFor="sellerTotalClosingCosts">Total Closing Costs:</label>
                                    </div>
                                    <div className="col-12 col-sm-7">
                                        <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="sellerTotalClosingCosts" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="sellerTotalClosingCosts" tabIndex="-1" className="form-control" {...register("sellerTotalClosingCosts")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideClosingCosts;