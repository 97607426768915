import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import { MdPersonOutline, MdEditNote, MdOutlineSave, MdOutlineWorkOutline, MdOutlineHelpOutline, MdOutlineImage, MdOutlineMail, MdOutlineDownload} from 'react-icons/md'
import { useForm } from "react-hook-form";
import SpinnerLoader from '../../layout/spinner-loader';
import AddYourLogo from '../add-your-logo';
import apiTools from '../../../services/api-tools';
import fileDownload from 'js-file-download';
import { BrandContext } from '../../../contexts/brand-context';
import { UserContext } from '../../../contexts/user-context';
import EmailNetSheet from './email-net-sheet';
import { EventTrack } from '../../../services/utilities';

const NetSheetOptionsMenu = ({ sideShowing, setSideShowing, showLogo, saveNetSheet, netSheetName, setNetSheetName, netSheetSaved, setNetSheetSaved, completeNetSheetObj, setDownloading, downloading, includeLogo, setIncludeLogo, logActivity}) => {
    const { selectedBrand } = useContext(BrandContext);
    const { activeRegion } = useContext(UserContext);
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = data => {
        setNetSheetName(data.netSheetName);
        saveNetSheet();
        setLoading(true);
    }
  
    useEffect(() => {
        if (!netSheetSaved) return;
        if (downloading && completeNetSheetObj) { 
            apiTools.post(`/net-sheet/download-email`, {NetSheetData: completeNetSheetObj, 
                SelectedBrand: selectedBrand, 
                includeLogo: includeLogo, 
                side: sideShowing,
                emailOrDownload: downloading.to ? "email" : "download", 
                to:  downloading.to, 
                notes: downloading.emailNetSheetNotes
                }
                , { responseType: 'blob' })
                .then((response) => {
                    setNetSheetSaved(false);
                    if (downloading.to) {
                        logActivity("Netsheet Emailed", `Buyer: ${completeNetSheetObj.netSheetFormData.buyerName}, \nSeller: ${completeNetSheetObj.netSheetFormData.sellerName}, \nAddress: ${completeNetSheetObj.netSheetFormData.propertyAddress}, \nBy: ${completeNetSheetObj.netSheetFormData.preparedBy}`);
                        includeLogo ? logActivity("Netsheet Emailed", `Logo Used`) : logActivity("Netsheet Emailed", `Logo Not Used`);
                        EventTrack("Net Sheet", "Emailed", sideShowing);
                    } else {
                        logActivity("Netsheet Printed", `Buyer: ${completeNetSheetObj.netSheetFormData.buyerName}, \nSeller: ${completeNetSheetObj.netSheetFormData.sellerName}, \nAddress: ${completeNetSheetObj.netSheetFormData.propertyAddress}, \nBy: ${completeNetSheetObj.netSheetFormData.preparedBy}`);
                        includeLogo ? logActivity("Netsheet Printed", `Logo Used`) : logActivity("Netsheet Printed", `Logo Not Used`);
                        EventTrack("Net Sheet", "Downloaded", sideShowing);
                    }
                if (response.statusText === 'No Content' || response.status !== 200) return;
                fileDownload(response.data, `${selectedBrand.brandName.toLowerCase().replace(/ /g, "-")}-net-sheet.pdf`);
              
            });
        }
        setDownloading(false);
        setLoading(false);
    }, [netSheetSaved]);

    const doDownload = (data) => {
        setDownloading(data ? data : true);
        saveNetSheet();
    }
    useEffect(() => {
        if (!netSheetName) return;
        setValue('netSheetName', netSheetName);
    }, [netSheetName]);

    return (<>
        <div className="col">
	        <div className="d-flex justify-content-around flex-wrap">
                {sideShowing !== 'input' && <Link to="" onClick={() => setSideShowing('input')}><MdEditNote className="sm-react-icons" /> Edit Common Data</Link>}
                {sideShowing !== 'input' && (sideShowing === 'buyer' ? 
                    <Link to="" onClick={() => { setSideShowing('seller'); EventTrack("Net Sheet", "View seller's results", "Link"); }} ><MdPersonOutline className="sm-react-icons" /> Switch to Seller's</Link> :
                    <Link to="" onClick={() => { setSideShowing('buyer'); EventTrack("Net Sheet", "View buyer's results", "Link"); }} ><MdPersonOutline className="sm-react-icons" /> Switch to Buyer's</Link>
                )}                                      
                {sideShowing !== 'input' && <Link to="" data-bs-toggle="modal" data-bs-target="#saveNetSheetModal"><MdOutlineSave className="sm-react-icons" /> Save</Link>}
                <Link to="/net-sheet-comparison" onClick={() => { EventTrack("Net Sheet List", "View Saved", "Net Sheet"); }}><MdOutlineWorkOutline className="sm-react-icons" /> View/Compare Saved</Link>
                {sideShowing !== 'input' && showLogo && <Link to="" data-bs-toggle="modal" data-bs-target="#addLogoModal"><MdOutlineImage className="sm-react-icons" /> Add Your Logo</Link>}   
                {sideShowing !== 'input' && showLogo && <Link to="" data-bs-toggle="modal" data-bs-target="#emailNetSheetModal"><MdOutlineMail className="sm-react-icons" /> Email</Link>}
                {sideShowing !== 'input' && showLogo && <button className="btn btn-link p-0 border-0" onClick={() => doDownload()}><MdOutlineDownload className="sm-react-icons" /> Download</button>}
                <a href={"mailto:" + activeRegion.stateAndCounties[0].csEmailAddress}><MdOutlineHelpOutline className="sm-react-icons" /> Need Help</a>     
            </div>
        </div>
        <div className="modal" id="saveNetSheetModal" tabIndex="-1" aria-labelledby="saveNetSheetModalLabel" aria-hidden="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="saveNetSheetModalLabel">Save Net Sheet</h5>
                    </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {netSheetSaved && !loading && <p>Thank you, your net sheet has been saved.</p>} 
                            {!netSheetSaved && !loading && <div className="row">
                                <div className="col-12 mt-2">
                                    <label className="fw-bold required">Net Sheet Name:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <input type="text" className="form-control" id="netSheetName" {...register("netSheetName", { required: true, maxLength: 50 })} />
                                    {errors?.netSheetName?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.netSheetName?.type === "maxLength" && <small className="text-danger">Name is too long. 50 character maximum.</small>}
                                </div>
                            </div>}
                        {loading && <SpinnerLoader/> }
                        <div className="modal-footer">
                            {!netSheetSaved && <button type="submit" className="btn btn-primary m-auto">Save Net Sheet</button>}
                                <span onClick={() => setNetSheetSaved(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{netSheetSaved ? "Close" : "Cancel"}</button></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {showLogo && <><EmailNetSheet doDownload={doDownload} downloading={downloading} />
        <AddYourLogo toolName="Net Sheet" setIncludeLogo={setIncludeLogo} />
        </>}
        </>
    );
}

export default NetSheetOptionsMenu;